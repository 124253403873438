import './App.css';
import React, { useState, useEffect, useMemo } from 'react';

const daysSinceDate = (targetDate) => {
  // Create a Date object for the current date
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - targetDate;

  // Convert milliseconds to days
  const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));

  return daysDifference;
}

const timeSinceDate = (targetDate) => {
  // Create a Date object for the current date
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - targetDate;

  // Calculate the time components
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // Calculate remaining hours, minutes, and seconds
  const remainingHours = hours % 24;
  const remainingMinutes = minutes % 60;
  const remainingSeconds = seconds % 60;

  // Format the result as "days : hours : minutes : seconds"
  const formattedTime = `${days} dager, ${remainingHours} timer, ${remainingMinutes} minutter, ${remainingSeconds} sekunder`;

  return formattedTime;
}

const Level = ({ title, value, postfix }) => {
  return (
    <div className="level">
      {title && <h2 className="subtitle">{title}</h2>}
      <div className="title">{value} {postfix}</div>
    </div>
  )
}

function App() {
  const targetDate = useMemo(() => new Date('2023-09-17'), []);

  const [elapsedTime, setElapsedTime] = useState(timeSinceDate(targetDate));

  const days = daysSinceDate(targetDate);
  const saved = 45 * days;

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime(timeSinceDate(targetDate));
    }, 1000);
    return () => clearInterval(interval);
  }, [targetDate]);

  return (
    <div className="App">
      <header className="App-header">
        <Level title="Snusfri" value={elapsedTime} />
        <br />
        <Level value={days * 24} postfix="timer totalt" />
        <Level title="Penger spart" value={saved} postfix="Kr" />
      </header>
    </div>
  );
}

export default App;
